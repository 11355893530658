<template>
  <div class="widgetContainer bg-white contact-details-form">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span v-if="page !== 'contact'" class="icon-Arrow-big-left cancel" @click="back" />
        <span class="icon-Arrow-big-left cancel" @click="cancel" v-if="(this.getCreateContactData && this.getCreateContactData.id)" />
        <p class="title">
          {{
            $t(
              getCreateContactData && getCreateContactData.id
                ? 'contact_details_EditTitle'
                : 'contact_Details_NavTitle'
            )
          }}
        </p>
        <span v-if="page === 'contact'" class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="contactDetail"
        :model="formData"
        :rules="formRules">
        <el-form-item
          class="is-no-asterisk"
          prop="name"
          :label="$t('contact_Details_Name')">
          <el-input v-model="formData.name" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="phone"
          :label="$t('contact_Details_Phone')">
          <MobileInput :value="formData.phone" @input="mobileNumberUpdate" :show-country-code-selector="true" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="email"
          :label="$t('contact_Details_Email')">
          <el-input v-model="formData.email" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        @click="next()"
        class="width-100 el-button__brand brand contact-details-form__done-btn">
        {{ $t('contact_Details_NavRightButton_Title') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import MobileInput from '@/components/MobileInput';
import ContactMixin from '../mixins/contact';
import { mapMutations, mapGetters, mapActions } from 'vuex';
export default {
  name: 'ContactDetailForm',
  mixins: [ContactMixin],
  props: {
    page: {
      type: String,
      default: 'contact'
    }
  },
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: ''
      },
      isPhoneNumberValid: false,
      formRules: {
        name: [
          {
            required: true,
            message: this.$t('businessNameOrFullNameRequired'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('emailInvalid'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  components: {
    MobileInput
  },
  computed: {
    ...mapGetters('contact', ['getCreateContactData']),
    isFormValid() {
      return this.formData.name !== '' && (this.formData.phone === '' ||  this.isPhoneNumberValid);
    }
  },
  created() {
    if (this.getCreateContactData) {
      this.formData = {
        name: this.getCreateContactData.name,
        phone: this.getCreateContactData.phone,
        email: this.getCreateContactData.email
      };
      if(this.getCreateContactData.phone) {
        this.isPhoneNumberValid = true;
      }
    }
  },
  methods: {
    ...mapMutations('contact', [
      'updateCreateContactData',
      'updateSelectedContactData'
    ]),
    ...mapActions('contact', ['createContact', 'updateContact']),
    mobileNumberUpdate(v) {
      if(v) {
        const {value, isValid} = v;
        this.formData.phone = value;
        this.isPhoneNumberValid = isValid;
      }
    },
    next() {
      this.$refs.contactDetail.validate((valid) => {
        if (!valid) return;

        const data = this.getCreateContactData || {};
        this.updateCreateContactData({ ...data, ...this.formData });

        let action = 'createContact';
        if (data.id) {
          action = 'updateContact';
        }

        const loader = this.showLoader();
        this[action]({
          ...data,
          ...this.formData,
          accountId: this.getSelectedAccount.id
        })
          .then((res) => {
            this.updateCreateContactData(res);
            this.updateSelectedContactData(res);
            if (action === 'createContact') {
              setTimeout(() => {
                if(this.page === 'contact') {
                  this.drawerPush('contact-success');
                } else {
                  this.drawerReplace('contact-success');
                }
                // this.$router.replace('/contact/success');
              }, 0);
            } else {
              this.$emit('fetchContacts');
              this.drawerBack();
              //this.goToPreviousScreen();
            }
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      });
    },
    cancel() {
      if (this.getCreateContactData && this.getCreateContactData.id) {
        //this.$router.push('/contact/details');
        this.drawerPush('details');
      } else {
        this.drawerClose();
        // this.goToPreviousScreen();
      }
    },
    back() {
      this.drawerReplace('CheckDeposit-contacts');
    }
  }
};
</script>
<style lang="scss" scoped>
.contact-details-form {
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 16px;
    border-top: 1px solid #eeeeee;
  }
  &__done-btn {
    &.is-disabled{
      opacity: 0.5;
    }
    width: 100%;
  }
}
</style>